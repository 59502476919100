import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import MovieScene from 'components/MovieScene';

import Clip from './ch4-SCENE-03-Coretta_Scott_King_Meets_Malcom_X.mp4';
import scene from 'components/scene';

const Chapter4Scene4 = ({data}) => (
  <div className="chapter-4-scene-2">
    <MovieScene
      sceneNumber={4}
      sceneSubtitle={
        <span><span className="italic">SELMA</span> SCENE 4</span>
      }
      sceneDescription="Coretta Scott King meets Malcolm X"
      firstImage={data.firstImage}
      lastImage={data.lastImage}
      videoSrc={Clip}
    />
  </div>
);

const query = graphql`
  query {
    firstImage: file(relativePath: { eq: "chapter-4/scene-4/images/ch-4-image-malcolm-x-coretta-king-still@2x.png" }) {
      ...largeImage
    }
    lastImage: file(relativePath: { eq: "chapter-4/scene-4/images/ch-4-image-malcolm-x-coretta-king-replay@2x.png" }) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(Chapter4Scene4);
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
