import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import timeline from 'components/timelineComponent';
import TextMask from 'components/TextMask';

import './chapter-4-scene-3.scss';

import {placeholder} from 'utils/animation';
import scene from 'components/scene';
import {getPercentageBetweenBounds} from 'utils'


class C4S3 extends React.Component {
  render() {
    const {
      VotingRightsBW,
      VotingRightsRed,
    } = this.props.data;
    const {position} = this.props;

    return (
      <div className="chapter-4-scene-3">
        <div className="chapter-4-scene-3__mask" ref="mask">
          <TextMask
            className="mask"
            backgroundImage={VotingRightsBW}
            maskImage={VotingRightsRed.childImageSharp.fluid.src}
            text="SUPPORTERS OF VOTING RIGHTS"
            position={position}
          />
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut, easeIn}) => {
  placeholder(timeline, 1.5);
  timeline.markers.createTimelineMarker();
  placeholder(timeline, 1.5);

};

const query = graphql`
  query {
    VotingRightsBW: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch4-divider-suppoters_voting_rights-bw.jpg" }
    ) {
      ...mediumImage
    }
    VotingRightsRed: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch4-divider-suppoters_voting_rights-red.png" }
    ) {
      ...mediumImage
    }
  }
`;

const SceneComponent = scene(timeline(C4S3, {animation}), {stopAtEnd: false});
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
