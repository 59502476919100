import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TabbedContent, {MemberThumb} from 'components/TabbedContent';

import Modal from 'components/ChapterModal';
import Image from 'components/Image';
import {stickyPositions, modalBackgrounds} from 'components/ModalContent';

import DvclModal from './DvclModal';
import SclcModal from './SclcModal';

import './scene-3-tabbed.scss';
import {tabbable} from '../../../../utils/accessibility';

const getTabsData = (images) => [
  {
    title: <span>Dallas County<br />Voters League</span>,
    leftContent: (
      <div className="tab1__left">
        <h5>DCVL</h5>
        <h3>Dallas County Voters League</h3>
        <p className="alt">A local group founded in the 1920s led mainly by Selma teachers, it worked to encourage black citizens to register to vote and held classes to prepare them for the literacy and civics tests.</p>
        <h5 {...tabbable}>Leader</h5>
        <div className="members">
          <MemberThumb image={images.Boynton} name="Amelia Boynton" />
        </div>
        <Modal className="tab1__left--modal"
          stickyPosition={stickyPositions.NO_STICKY}
          modalBackground={modalBackgrounds.WHITE}>
          <DvclModal />
        </Modal>
      </div>
    ),
    rightContent: images.HelpVote,
    rightAlt: 'P. H. Lewis of the Dallas County Voters League, speaking to an audience during a meeting in a church building. View Description Download',
  },
  {
    title: <span>Student Nonviolent<br />Coordinating Committee</span>,
    leftContent: (
      <div className="tab2__left">
        <h5>SNCC</h5>
        <h3>Student Nonviolent Coordinating Committee</h3>
        <p className="alt">SNCC organized students to take direct action such as sit-ins, freedom rides, and voter registration drives to bring an end to segregation. </p>
        <h5 {...tabbable}>Important early members</h5>
        <div className="members">
          <MemberThumb image={images.Lewis} name="John Lewis" />
          <MemberThumb image={images.LaFayette} name="Bernard LaFayette" starred />
          <MemberThumb image={images.Nash} name="Diane Nash" />
          <MemberThumb image={images.Forman} name="James Forman" />
          <MemberThumb image={images.Bond} name="Julian Bond" />
        </div>
        <div className="footnote">
          <div className="footnote__asterisk">*</div>
          <div className="footnote__attribution">Alabama Department of Archives and History. Donated by Alabama Media Group. Photo by Robert Adams or Ed Jones, Birmingham News.</div>
        </div>
      </div>
    ),
    rightContent: images.Siting,
    rightAlt: 'USA. Atlanta, Georgia. 1963. SNCC staff sit-in at a Toddle House.',
  },
  {
    title: <span>National Association for<br />the Advancement of Colored People</span>,
    leftContent: (
      <div className="tab3__left">
        <h5>NAACP</h5>
        <h3>National Association<br />for the Advancement<br />of Colored People</h3>
        <p className="alt">
          The nation’s oldest civil rights organization (founded in 1909) was a formidable national organization that directly lobbied the President and both parties in Congress.<br /><br />The NAACP Legal Defense Fund (NAACP-LDF) provided legal advice and support to SCLC and SNCC in Selma.
        </p>
      </div>
    ),
    rightContent: (
      <div className="tab3__right" role="image" aria-label="President Lyndon B. Johnson (seated, center) meets with Roy Wilkins and NAACP officials" style={{backgroundImage: `url(${images.Naacp.childImageSharp.fluid.src})`, backgroundSize: 'cover'}}>
        <div className="floating-label">
          <div className="roy">
            <Image image={images.DoodleWilkins} alt="Arrow Wilkins"/>
            <p className="highlight">Roy Wilkins,</p>
            <p className="sub highlight">- Leader, NAACP</p>
          </div>
          <div className="lbj">
            <Image image={images.DoodleLJB} alt="Arrow LJB"/>
            <p className="highlight">LBJ</p>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: `Southern Christian Leadership Conference`,
    leftContent: (
      <div className="tab4__left">
        <h5>SCLC</h5>
        <h3>Southern Christian<br />Leadership Conference</h3>
        <p className="alt">
          The SCLC, whose first president was Martin Luther King Jr., grew out of the successful Montgomery Bus Boycott.<br /><br />The group worked to support and direct opposition to segregation by collaborating closely with Christian<br />churches and prayer meetings, mass demonstrations,<br />and peaceful marches.
        </p>
        <h5 {...tabbable}>Leaders</h5>
        <div className="members">
          <MemberThumb image={images.Mlk} name="Dr. Martin Luther King Jr." />
          <MemberThumb image={images.Csk} name="Coretta Scott King" />
        </div>
        <ul className="caption" {...tabbable}>
          <li>MLK’s wife and closest confidante.</li>
          <li>Active in global peace and anti-nuclear movements.</li>
          <li>Willing to work with activists like Malcolm X who shared the same broad goal of equal rights but differed in tactics.</li>
        </ul>
        <Modal className="tab4__left--modal"
          stickyPosition={stickyPositions.NO_STICKY}
          modalBackground={modalBackgrounds.WHITE}>
          <SclcModal />
        </Modal>
      </div>
    ),
    rightContent: images.Kneeling,
    rightAlt: 'Six mixed SCLC demonstrators kneel on sidewalk in front of Methodist Church after they were turned away after trying to integrate the church August 1st. The mayor of this southwest Georgia town is a member of this church.',
  },
  {
    title: `Malcolm X`,
    leftContent: (
      <div className="tab5__left">
        <h5>Malcolm X</h5>
        <p className="alt">The nation’s leading Black Muslim, he was a riveting public speaker and an effective critic of the non-violent philosophy and tactics of King and the SCLC.<br /><br />Malcolm was assassinated in New York in February 1965, just after his meeting with Coretta Scott King in Selma.</p>
      </div>
    ),
    rightContent: images.MalcolmX,
    rightAlt: 'USA. District of Columbia. Town of Washington D.C. 1961. MALCOLM X at a Black Muslim rally',
  },
];

class Scene3TabbedContent extends React.Component {
  constructor(props) {
    super(props);
    this.tabsData = getTabsData(props.data);
  }

  render() {
    return (
      <TabbedContent
        className="chapter-4-scene-3__tabs"
        theme="dark"
        title="Supporters of Voting Rights"
        tabsData={this.tabsData}
      />
    );
  }
}

const query = graphql`
  query {
    HelpVote: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-5-image-helping-people-vote@2x.png" }
    ) {
      ...mediumImage
    }
    Boynton: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-boynton-thumbnail@2x.png" }
    ) {
      ...doodleImage
    }
    Siting: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-counter-sitin@2x.png" }
    ) {
      ...largeImage
    }
    Lewis: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-john-lewis-thumbnail@2x.png" }
    ) {
      ...doodleImage
    }
    LaFayette: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-lafayette-thumbnail@2x.png" }
    ) {
      ...doodleImage
    }
    Nash: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-nash-thumbnail@2x.png" }
    ) {
      ...doodleImage
    }
    Nash: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-nash-thumbnail@2x.png" }
    ) {
      ...doodleImage
    }
    Forman: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-forman-thumbnail@2x.png" }
    ) {
      ...doodleImage
    }
    Bond: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-bond-thumbnail@2x.png" }
    ) {
      ...doodleImage
    }
    Naacp: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-naacp@2x.png" }
    ) {
      ...mediumImage
    }
    Kneeling: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-kneeling-group@2x.png" }
    ) {
      ...mediumImage
    }
    Mlk: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-king-thumbnail@2x.png" }
    ) {
      ...doodleImage
    }
    Csk: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-csk-thumbnail@2x.png" }
    ) {
      ...doodleImage
    }
    MalcolmX: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-image-malcolmx@2x.png" }
    ) {
      ...mediumImage
    }
    DoodleLJB: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-doodle-arrow-lbj-naacp@2x.png" }
    ) {
      ...doodleImage
    }
    DoodleWilkins: file(
      relativePath: { eq: "chapter-4/scene-3/images/ch-4-doodle-arrow-roy-wilkins@2x.png" }
    ) {
      ...doodleImage
    }
  }
`;

export default (props) => {
  const data = useStaticQuery(query);
  return <Scene3TabbedContent data={data} {...props}/>;
};
