import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import timeline from 'components/timelineComponent';
import TextMask from 'components/TextMask';

import './chapter-4-scene-6.scss';

import {placeholder} from 'utils/animation';
import scene from 'components/scene';
import {getPercentageBetweenBounds} from 'utils';



class C4S6 extends React.Component {
  render() {
    const {position} = this.props;
    return (
      <div className="chapter-4-scene-6">
        <div className="chapter-4-scene-6__mask" ref="mask">
          <TextMask
            className="mask"
            backgroundImage={this.props.data.SegregationstBg}
            maskImage={this.props.data.SegregationistRed.childImageSharp.fluid.src}
            text="SEGREGATIONIST GROUPS"
            position={position}
          />
        </div>
      </div>
    );
  }
}


const animation = ({context, timeline, easeOut, easeIn}) => {
  placeholder(timeline, 1.5);
  timeline.markers.createTimelineMarker();
  placeholder(timeline, 1.5);

};

const query = graphql`
  query {
    SegregationstBg: file(
      relativePath: { eq: "chapter-4/scene-6/images/ch-4-image-segregationist-protest-foreground.jpg" }
    ) {
      ...mediumImage
    }
    SegregationistRed: file(
      relativePath: { eq: "chapter-4/scene-6/images/ch4-image-segregationist_protest-foreground-red.png" }
    ) {
      ...mediumImage
    }
  }
`;

const SceneComponent = scene(timeline(C4S6, {animation}), {stopAtEnd: false});
export default props => {
  const data = useStaticQuery(query)
  return <SceneComponent data={data} {...props} />
}
