import React from 'react';
import {TweenMax} from 'gsap';
import {useStaticQuery, graphql} from 'gatsby';

import timeline from 'components/timelineComponent';
import TextMask from 'components/TextMask';
import FullScreenContainer from 'components/FullScreenContainer';
import {
  placeholder, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import './chapter-4-scene-7.scss';

import {getPercentageBetweenBounds} from 'utils';
import scene from 'components/scene';


const textMaskStart = 0;
const textMaskEnd = 0.66;

class C4S7 extends React.Component {
  render() {
    const {
      CapitalBuilding,
      CapitalBuildingRed,
      CapitalBuildingBG,
    } = this.props.data;
    const {position} = this.props;
    const textMaskProgress = getPercentageBetweenBounds(position, textMaskStart, textMaskEnd);

    return (
      <div className="chapter-4-scene-7">
        <div className="chapter-4-scene-7__mask">
          <div ref="mask">
            <TextMask
              className="mask"
              backgroundImage={CapitalBuilding}
              maskImage={CapitalBuildingRed.childImageSharp.fluid.src}
              text="FEDERAL GOVERNMENT"
              aspectRatio={2880/1840}
              position={textMaskProgress}
            />
          </div>
          <div ref="voting">
            <FullScreenContainer
              className="voting-rights"
            >
              <div className="voting-rights__text">
                <h3 {...tabbable} ref="text">Voting rights was a federal matter,<br />so the battle for equal voting rights in Selma<br />would be decided in Washington, DC.</h3>
              </div>
            </FullScreenContainer>
          </div>
        </div>
      </div>
    );
  }
}


const animation = ({context, timeline, easeOut, easeIn}) => {

  const {mask, voting, text} = context.refs;
  TweenMax.set(voting, {...hideElementWithAria});
  TweenMax.set(text, {...hideFromBelow});

  placeholder(timeline, 1.5);
  timeline.markers.createTimelineMarker();
  placeholder(timeline, 1.5);

  timeline.to(voting, 1, {...showElemWithAria}, "-=0.5");
  timeline.to(text, 1.5, {...revealFromBelow, ...easeOut}, '-=0.5');

  timeline.markers.createTimelineMarker();

};

const query = graphql`
  query {
    CapitalBuilding: file(
      relativePath: { eq: "chapter-4/scene-7/images/ch-4-image-capital-building-foreground.jpg" }
    ) {
      ...largeImage
    }
    CapitalBuildingRed: file(
      relativePath: { eq: "chapter-4/scene-7/images/ch4-image-capital_building-foreground-red.png" }
    ) {
      ...largeImage
    }
    CapitalBuildingBG: file(
      relativePath: { eq: "chapter-4/scene-7/images/ch-4-image-capital-building-bg@2x.png" }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(C4S7, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
