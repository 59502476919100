import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Image from 'components/Image';

import './chapter-4-scene-3.scss';
import DallasCounty from './images/info-dallas-black-white-pop-map@2x.png'
import PieChart from './images/info-dallas-black-white-reg-pie@2x.png'

const DvclModal = (props) => {
  const {
    DallasCounty,
    voteLeft,
    voteRight
  } = props.data

  return (
    <div className="dvcl-modal">
      <div className="dvcl-modal__content">
        <h4 className="uppercase">Dallas County Population</h4>
        <div className="dvcl-modal__sections">
          <div className="dvcl-modal__sections__section dallas-county">
            <div className="dvcl-modal__image">
              <Image image={DallasCounty} alt="Dallas County"/>
            </div>
          </div>
          <div className="dvcl-modal__sections__section figures">
            <div className="vote-left">
              <div className="dvcl-modal__image africanamerican-population">
                <Image image={voteLeft} alt="voting image africanamerican-population"/>
              </div>

              <div className="dvcl-modal__figures">
                <div className="dvcl-modal__figures__figure normal">
                  <p className="percentage">57%</p>
                  <p className="subtitle">African American</p>
                </div>
                <div className="arrow">
                  <div className="arrow__shape" />
                </div>
                <div className="dvcl-modal__figures__figure highlight">
                  <p className="percentage">1%</p>
                  <p className="subtitle">Registered to Vote</p>
                </div>
              </div>
            </div>
            <div className="vote-right">

              <div className="dvcl-modal__image white-population">
                <Image image={voteRight} alt="voting image left"/>
              </div>

              <div className="dvcl-modal__figures">
                <div className="dvcl-modal__figures__figure normal">
                  <p className="percentage">43%</p>
                  <p className="subtitle">White</p>
                </div>
                <div className="arrow">
                  <div className="arrow__shape" />
                </div>
                <div className="dvcl-modal__figures__figure highlight">
                  <p className="percentage">64%</p>
                  <p className="subtitle">Registered to Vote</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
};



const query = graphql`
  query {
    DallasCounty: file(
      relativePath: { eq: "chapter-4/scene-3/images/icon-dallas-county@2x.png" }
    ) {
      ...mediumImage
    }
    voteLeft: file(
      relativePath: { eq: "chapter-4/scene-3/images/population-africanamerican.png" }
    ) {
      ...mediumImage
    }
    voteRight: file(
      relativePath: { eq: "chapter-4/scene-3/images/population-white.png" }
    ) {
      ...mediumImage
    }
  }
`;

export default (props) => {
  const data = useStaticQuery(query);
  return <DvclModal data={data} {...props}/>;
};
