import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import timeline from 'components/timelineComponent';
import TextMask from 'components/TextMask';

import './chapter-4-scene-5.scss';

import {placeholder} from 'utils/animation';
import scene from 'components/scene';

import {getPercentageBetweenBounds} from 'utils'



class C4S5 extends React.Component {
  render() {
    const {
      Police,
      PoliceRed,
    } = this.props.data;
    const {position} = this.props;

    return (
      <div className="chapter-4-scene-5">
        <div className="chapter-4-scene-5__mask" ref="mask">
          <TextMask
            className="mask"
            backgroundImage={Police}
            maskImage={PoliceRed.childImageSharp.fluid.src}
            text="OPPONENTS OF THE CIVIL RIGHTS MOVEMENT"
            position={position}
          />
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut, easeIn}) => {
  placeholder(timeline, 1.5);
  timeline.markers.createTimelineMarker();
  placeholder(timeline, 1.5);

};


const query = graphql`
  query {
    Police: file(
      relativePath: { eq: "chapter-4/scene-5/images/ch-4-image-police-lineup-foreground.jpg" }
    ) {
      ...largeImage
    }
    PoliceRed: file(
      relativePath: { eq: "chapter-4/scene-5/images/ch4-image-police_lineup-foreground-red.png" }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(C4S5, {animation}), {stopAtEnd: false});
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
