import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TabbedContent, {MemberThumb} from 'components/TabbedContent';

const getTabsData = (images) => [
  {
    title: `Local Goverment in Selma`,
    leftContent: images.SelmaGovernment.childImageSharp.fluid.src,
    leftAlt: "(Original Caption) Selma, Alabama: Selma's Mayor Joe Smitherman, (seated), held a press conference here today, (July 6), to announce that men from Selma Police Department and State Department of Public Safety, had arrested Reverend F. D. Reese of Selma, for embezzlement of Civil Rights funds. Officers that made the arrest and investigation are, (left to right): Detective Let (?) S Dan Knight, Walter D. Nichols, Selma Police Department State Investigator Rufas Head, and Selma's Safety Director, Wilson Baker.",
    rightContent: (
      <div className="tab1__right">
        <h3>Local Government in Selma</h3>
        <p className="alt">Local politicians and officials (all white) were opposed to equal voting rights.</p>
        <div className="members">
          <MemberThumb
            image={null}
            name="James Hare"
            title="Federal Judge"/>
          <MemberThumb
            image={images.JimClark}
            name="Jim Clark"
            title="Dallas County Sheriff"/>
          <MemberThumb
            image={images.JoesSmitherman}
            name="Joe Smitherman"
            title="Selma Mayor"/>
          <MemberThumb
            image={images.WilsonBaker}
            name="Wilson Baker"
            title="Selma Public Safety Director"/>
        </div>
      </div>
    )
  },
  {
    title: `Alabama State Government`,
    leftContent: images.AlabamaGov.childImageSharp.fluid.src,
    leftAlt: 'UNSPECIFIED - CIRCA 1754: George Wallace (1919-1998) Governor of Alabama for four terms between 1963 and 1987. Wallace attempting to block integration at the University of Alabama, standing defiantly at a door while being confronted by US Deputy Attorney General Nicholas Katzenbach, 11 June 1963. Photographer: Warren K Leffler. (Photo by Universal History Archive/Getty Images)',
    rightContent: (
      <div className="tab2__right">
        <h3>Alabama State Government</h3>
        <p className="alt">George Wallace, Governor of Alabama, strongly opposed desegregation. Wallace’s defiance encouraged local officials like Sheriff Clark and Judge Hare to harass and stymie the civil rights movement.</p>
      </div>
    )
  }
]

class Scene4TabbedContent extends React.Component {
  constructor(props) {
    super(props)
    this.tabsData = getTabsData(props.data)
  }
  render() {
    return (
      <TabbedContent
        theme="dark"
        title="Opponents of the Civil Rights Movement"
        tabsData={this.tabsData}
        />
    );
  }
}

const query = graphql`
  query {
    SelmaGovernment: file(
      relativePath: { eq: "chapter-4/scene-5/images/ch-4-image-selma-government@2x.png" }
    ) {
      ...largeImage
    }
    JimClark: file(
      relativePath: { eq: "chapter-4/scene-5/images/ch-4-image-clark-thumbnail@2x.png" }
    ) {
      ...doodleImage
    }
    JoesSmitherman: file(
      relativePath: { eq: "chapter-4/scene-5/images/ch-4-image-smitherman-thumbnail@2x.png" }
    ) {
      ...doodleImage
    }
    WilsonBaker: file(
      relativePath: { eq: "chapter-4/scene-5/images/ch-4-image-baker-thumbnail@2x.png" }
    ) {
      ...doodleImage
    }
    AlabamaGov: file(
      relativePath: { eq: "chapter-4/scene-5/images/ch-4-image-alabama-govt@2x.png" }
    ) {
      ...largeImage
    }
  }
`;

export default (props) => {
  const data = useStaticQuery(query);
  return <Scene4TabbedContent data={data} {...props}/>;
};
