import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import MovieScene from 'components/MovieScene';

import Clip from './ch4-SCENE-04-Strategy_&_Tactics.mp4';
import scene from 'components/scene';

const Chapter4Scene2 = ({data}) => (
  <div className="chapter-4-scene-2">
    <MovieScene
      sceneNumber={3}
      sceneSubtitle={
        <span><span className="italic">SELMA</span> SCENE 3</span>
      }
      sceneDescription="Strategy & Tactics"
      firstImage={data.firstImage}
      lastImage={data.lastImage}
      videoSrc={Clip}
    />
  </div>
);

const query = graphql`
  query {
    firstImage: file(relativePath: { eq: "chapter-4/scene-2/images/ch-4-image-sclc-vs-sncc-still@2x.png" }) {
      ...largeImage
    }
    lastImage: file(relativePath: { eq: "chapter-4/scene-2/images/ch-4-image-sclc-sncc-replay@2x.png" }) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(Chapter4Scene2);
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
