import React from 'react';
import PropTypes from 'prop-types';

import FullScreenContainer from './FullScreenContainer';
import Image from './Image';
import ScrollContainer from './ScrollContainer';
import scene from './scene';

import Timeline from 'components/timelineComponent';

import '../styles/components/tabbed-content.scss';
import {tabbable} from 'utils/accessibility'

export const MemberThumb = ({ image, name, title, starred }) => (
  <div className="member">
    {image ? (
      <div className="member__thumb">
        <Image image={image} alt={name}/>
        {starred &&
          <div className="member__thumb__asterisk">*</div>
        }
      </div>
    ) : (
      <div />
    )}
    <div className="member__name" {...tabbable} aria-label={`${name} ${title || ''}`}>
      <p className="alt">{name}</p>
      {title && <p className="caption">{title}</p>}
    </div>
  </div>
);

const propTypes = {
  title: PropTypes.string,
  tabsData: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    leftContent: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.string]),
    rightContent: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.string]),
  }))
};

const defaultProps = {
  title: ''
};

class TabbedContent extends React.Component {

  state = {
    currentTabIndex: 0
  };

  setIndex = currentTabIndex => {
    this.setState({ currentTabIndex });
  }

  onTabClick = (index) => {
    const {actions} = this.props
    const {jumpToStop} = actions
    jumpToStop(index)
  }

  getTabs = (children, tabIndex) =>
    children.map((child, index) => {
      return (
        <button className={`tab${index === tabIndex ? ' selected' : ''}`}
          tabIndex="0"
          key={`tab-${index}`} type="button"
          onClick={e => this.onTabClick(index)}>
          <span>{child.title}</span>
        </button>
      )
    });

  getContent = (children, tabIndex, leftSide = true) => {
    if (!children[tabIndex]) return

    const childElem = leftSide
      ? children[tabIndex].leftContent
      : children[tabIndex].rightContent;
    const {rightAlt, leftAlt} = children[tabIndex];
    const isObject = typeof childElem === 'object'

    return isObject && !childElem.childImageSharp  ? (
      <div {...tabbable} ref={ref => this.activeContent = ref} className="content">{childElem}</div>
    ) : (
      <div className="content img">
        <Image className="full-image" image={childElem} showAria={(!leftSide && rightAlt) || (leftSide && leftAlt)} alt={(leftAlt || rightAlt || '')} />
      </div>
    );
  };

  componentDidMount() {
    const { actions, tabsData } = this.props
    actions.registerSceneStops(
      tabsData.map((tab, id) => ({
        id,
        value: id / (tabsData.length - 1)
      }))
    )
  }

  componentWillReceiveProps(nextProps) {
    const newPosition = Math.round(
      nextProps.position * (nextProps.tabsData.length - 1)
    )
    this.setIndex(newPosition)
  }

  render() {
    const { currentTabIndex } = this.state
    const { title, tabsData, className } = this.props

    return (
      <FullScreenContainer className={`tabbed-content ${className}`}>
        <div className="tabbed-content__content">
          <ScrollContainer
            key={currentTabIndex}
            className="tabbed-content__content__scrollable">
            {tabsData && this.getContent(tabsData, currentTabIndex)}
          </ScrollContainer>
          <ScrollContainer
            key={`right-${currentTabIndex}`}
            className="tabbed-content__content__scrollable">
            {tabsData && this.getContent(tabsData, currentTabIndex, false)}
          </ScrollContainer>
        </div>
        <div className="tabbed-content__tabs">
          {tabsData && this.getTabs(tabsData, currentTabIndex)}
        </div>
        <div className="tabbed-content__header">
          <h4 className="highlight">{title}</h4>
        </div>
      </FullScreenContainer>
    );
  }
}

TabbedContent.propTypes = propTypes;
TabbedContent.defaultProps = defaultProps;

export default scene(Timeline(TabbedContent), {stopAtStart: true})
