import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import TabbedContent from 'components/TabbedContent';

const getTabsData = images => [
  {
    title: `White Citizens Council`,
    leftContent: (
      <div className="tab1__left">
        <h3>White Citizens Council</h3>
        <p className="alt">
          A group of white community leaders (e.g., bankers, newspaper editors, politicians and business owners) who organized throughout the South to resist desegregation.<br/><br/>
          Called the "Uptown Klan" by civil rights attorney and future Supreme Court Justice Thurgood Marshall, the members used their economic and political power to enforce<br/>white supremacy and oppose integration under<br/>the slogan, "Never!"
        </p>
      </div>
    ),
    rightContent: images.WhiteCitizensCouncil.childImageSharp.fluid.src,
    rightAlt: '11/16/1960-New Orleans, LA- Waving Confederate flags, two small children stand on chairs as they join adults in attending a meeting of the White Citizens Council of Greater New Orleans, 11/15. The meeting was called to protest the integration of two local elementary schools. On 11/16, a crowd of 5000 screaming mothers and teenagers converged on the local school board offices to protest the integration decision. Police used fire hoses and a flying wedge of motorcycles to halt demonstrators.'
  },
  {
    title: `Ku Klux Klan`,
    leftContent: (
      <div className="tab2__right">
        <h3>Ku Klux Klan</h3>
        <p className="alt">
          This white supremacist group from Reconstruction rose again in the 1950s and 1960s.<br/><br/>
          Members used violence—including bombings and murder—in a campaign of terror against supporters of the civil rights movement.
        </p>
      </div>
    ),
    rightContent: images.KKK.childImageSharp.fluid.src,
    rightAlt: 'USA. Atlanta. Georgia. Civil Rights. The Atlanta Movement. Winter 1963-1964.'
  }
]

class Scene6TabbedContent extends React.Component {
  constructor(props) {
    super(props)
    this.tabsData = getTabsData(props.data)
  }

  render() {
    return (
      <TabbedContent
        theme="dark"
        title="Segregationist Groups"
        tabsData={this.tabsData}
      />
    );
  }
}

const query = graphql`
  query {
    KKK: file(
      relativePath: { eq: "chapter-4/scene-6/images/ch-4-image-kkk@2x.png" }
    ) {
      ...mediumImage
    }
    WhiteCitizensCouncil: file(
      relativePath: { eq: "chapter-4/scene-6/images/ch-4-image-white-citizens-council@2x.png" }
    ) {
      ...mediumImage
    }
  }
`;

export default (props) => {
  const data = useStaticQuery(query);
  return <Scene6TabbedContent data={data} {...props} />;
};
