import React from 'react';
import { graphql } from 'gatsby';

import ChapterPage from 'components/chapter';
import ChapterIntro from 'templates/ChapterIntro';
import ChapterConclusion from 'components/ChapterConclusion';


import Chapter4Scene1 from 'components/scenes/chapter-4/scene-1/Chapter4Scene1';
import Chapter4Scene2 from 'components/scenes/chapter-4/scene-2/Chapter4Scene2';
import Chapter4Scene3 from 'components/scenes/chapter-4/scene-3/Chapter4Scene3';
import Scene3TabbedContent from 'components/scenes/chapter-4/scene-3/Scene3TabbedContent';
import Chapter4Scene4 from 'components/scenes/chapter-4/scene-4/Chapter4Scene4';
import Chapter4Scene5 from 'components/scenes/chapter-4/scene-5/Chapter4Scene5';
import Scene4TabbedContent from 'components/scenes/chapter-4/scene-5/Scene4TabbedContent';
import Chapter4Scene6 from 'components/scenes/chapter-4/scene-6/Chapter4Scene6';
import Scene6TabbedContent from 'components/scenes/chapter-4/scene-6/Scene6TabbedContent';
import Chapter4Scene7 from 'components/scenes/chapter-4/scene-7/Chapter4Scene7';
import Scene7TabbedContent from 'components/scenes/chapter-4/scene-7/Scene7TabbedContent';
import Chapter4Scene8 from 'components/scenes/chapter-4/scene-8/Chapter4Scene8';
import {Chapter4Qa} from 'components/scenes/chapter-4/scene-10/Chapter4Qa';

const title = 'Who’s Who in Selma'

const Chapter4 = ({data}) => (
  <div className="chapter-4">
    <ChapterPage
      chapterNumber={2}
      title={title}
      scenes={[
        <ChapterIntro
          title="Chapter 4"
          subtitle="Who's Who in Selma"
          hero={data.heroImage}
          alt="(Original Caption) 1/15/1965-Atlanta, GA: Dr. Martin Luther King points to Selma, Alabama on a map at a Southern Christian Leadership Conference office, as he calls for a three-pronged attack on racial barriers. Negro leaders indicate that January 18 will bring the biggest organized test to date of the new Civil Rights law. Volunteers are scheduled to march on the voter registration office, while others, too young to vote, will test the public accomodations section of the act. Still others will seek employment."
        />,
        <Chapter4Scene1 />,
        <Chapter4Scene2 />,
        <Chapter4Scene3 theme="dark"/>,
        <Scene3TabbedContent/>,
        <Chapter4Scene4 />,
        <Chapter4Scene5 theme="dark"/>,
        <Scene4TabbedContent/>,
        <Chapter4Scene6 theme="dark" />,
        <Scene6TabbedContent/>,
        <Chapter4Scene7 theme="dark"/>,
        <Scene7TabbedContent/>,
        <Chapter4Scene8 />,
        <ChapterConclusion
          image={data.Chapter4End}
          questions={Chapter4Qa}
          chapterNumber={4}
          nextChapterTitle={'Selma’s Pivotal Moments'}
          theme="dark"
        />
      ]}
    />
  </div>
)

export const query = graphql`
  query {
    heroImage: file(
      relativePath: { eq: "chapter-4/background@2x.jpg" }
    ) {
      ...largeImage
    }
    Chapter4End: file(
      relativePath: { eq: "chapter-2/scene-9/images/chapter-2-end@2x.png" }
    ) {
      ...largeImage
    }
  }
`;

export default Chapter4
