import React from 'react';

import './chapter-4-scene-3.scss';

const SclcModal = () => (
  <div className="sclc-modal">
    <h3>SCLC’s goal:</h3>
    <p className="quote hightlight">"</p>
    <p className="alt">
      to stimulate nonviolent, direct, mass action to expose and remove the barriers of segregation and discrimination; to disseminate the creative philosophy and techniques of nonviolence through local and area workshops; to secure the right and unhampered use of the ballot for every citizen; to achieve full citizenship rights; and to reduce the cultural lag through our citizenship training program.
    </p>
    <p className="label highlight">Dr. Martin Luther King, Jr.</p>
    <p className="caption">1965</p>
  </div>
);

export default SclcModal;
