export const Chapter4Qa = [
  {
    questionNumber: '4.01',
    question:
      'What are some examples of nonviolent strategies employed during the voting rights movement?',
    answer: `Answers will vary and may include: protests, marches, sit-ins, prayer meetings, mass demonstrations, mass arrests`
  },
  {
    questionNumber: '4.02',
    question: 'What philosophical arguments support a nonviolent strategy?',
    answer: `Answers will vary and may include: Nonviolent strategy seeks to build friendship and find common ground. Nonviolent strategy relies on education and reforming. Nonviolent strategy aims to repair broken systems and correct injustice not defeat people.`
  },
  {
    questionNumber: '4.03',
    question:
      'What leaders and groups were supporters of and involved in the voting rights movement in Selma?',
    answer: `Answers will vary and may include: SCLC, SNCC, DCVL, MLK, John Doar, NAACP, Coretta Scott King`
  },
  {
    questionNumber: '4.04',
    question:
      'What methods did these leaders and groups use to organize people in Selma?',
    answer: `These leaders and groups used mass meetings, scheduled marches, organized protests, prayer meetings, soliciting media attention, voter registration education, nonviolent protest and mass arrest.`
  },
  {
    questionNumber: '4.05',
    question:
      'What leaders and groups posed obstacles to the voting rights movement?',
    answer: `The White Citizens Council, the KKK, Alabama state officials who believed in segregation, Sheriff Jim Clark, at times LBJ and the FBI posed obstacles to the voting rights movement.`
  },
  {
    questionNumber: '4.06',
    question:
      'What methods did opposition groups use to prevent African Americans from voting?',
    answer: `Opposition groups like the White Citizens Council and the KKK used violence and intimidation to prevent African Americans from voting.`
  },
  {
    questionNumber: '4.07',
    question:
      'What risks did civil rights activists face  in resisting opposition?',
    answer: `Answers will vary and may include; Civil rights activists faced loss of employment, harassment, intimidation, arrest, physical harm.`
  }
];
