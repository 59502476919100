import React from 'react';
import { TweenMax } from 'gsap';
import { useStaticQuery, graphql } from 'gatsby';

import './chapter-4-scene-1.scss';

import timeline from 'components/timelineComponent';
import { Image } from 'components/Image';
import Modal from 'components/ChapterModal';
import ActorPortrait from 'components/ActorPortrait';
import FullScreenContainer from 'components/FullScreenContainer';
import { tabbable } from 'utils/accessibility';
import {
  stickyPositions,
  modalWindowBackgrounds
} from 'components/ModalContent';
import SplitView from 'templates/SplitView';

import {
  placeholder,
  hideAllRefs,
  showElemWithAria,
  hideElementWithAria,
  hideFromBelow,
  revealFromBelow
} from 'utils/animation';
import scene from 'components/scene';
import { addTab, removeTab } from '../../../../utils/animation';

class C4S1 extends React.Component {
  render() {
    const {
      SclcBg,
      Amelia,
      Quote,
      Lorraine,
      panelOne,
      panelTwo,
      panelThree
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-4-scene-1">
        <div className="panel" ref="panelRef">
          <SplitView
            fullWidthPanel={
              <div className="panel__full">
                <div className='outer-img-container' ref="panelOneContainer" role='img' aria-label='Young team supports "Register to Vote" rally with signs.'>
                  <Image
                    image={panelOne.childImageSharp.fluid.src}
                    alt='Young team supports "Register to Vote" rally with signs.'
                    caption="Bob Fitch Photography Archive, Department of Special Collections, Stanford University Libraries"

                  />
                </div>
                <div className='outer-img-container' ref="panelTwoContainer" role='img' aria-label='Sheriff Jim Clark speaking to "Freedom Day" marchers at the Dallas County Courthouse in Selma, Alabama, during a voting rights demonstration.'>
                  <Image
                    image={panelTwo.childImageSharp.fluid.src}
                    alt='Sheriff Jim Clark speaking to "Freedom Day" marchers at the Dallas County Courthouse in Selma, Alabama, during a voting rights demonstration.'
                    caption="Alabama Department of Archives and History. Donated by Alabama Media Group. Photo by Ed Jones, Birmingham News."
                  />
                </div>
                <Image
                  image={panelThree.childImageSharp.fluid.src}
                  alt="State sigil"
                  caption=""
                />
              </div>
            }
            leftPanel={
              <div className="panel__left" ref="firstPanelContent">
                <div {...tabbable} className="content">
                  <p ref="introText1">
                    Many individuals and groups feature in the Selma story.
                    <br />
                    Some supported voting equality, others opposed it.
                  </p>
                  <p ref="introText2" className="alt">
                    Beyond concerned citizens and organized activists, local,
                    state, and federal politicians all played a decisive role.
                  </p>
                </div>
              </div>
            }
          />
        </div>
        <div className="amelia" ref="ameliaRef">
          <SplitView
            fullWidthPanel={
              <div className="amelia__full" ref="slcBg" role='img' aria-label="Marchers with SCLC sign for the Savannah Freedom Now Movement, during the March on Washington, 1963">
                <Image image={SclcBg} alt="Marchers with SCLC sign for the Savannah Freedom Now Movement, during the March on Washington, 1963" />
              </div>
            }
            shouldCancelLeftScroll
            leftPanel={
              <div className="amelia__left" ref="ameliaLeft">
                <div className="amelia-img" ref="ameliaImg" role='img' aria-label="American Civil Rights activists Gloria Richardson (nee St Clair, later Dandridge) (center, in white shirt) and Amelia Boynton (nee Platts, later Robinson, 1911 - 2015) (second right, in flower-print dress) lead a march of a pro-integration march, Cambridge, Maryland, May 1964. (Photo by Francis Miller/The LIFE Picture Collection via Getty Images)">
                  <Image image={Amelia} alt="American Civil Rights activists Gloria Richardson (nee St Clair, later Dandridge) (center, in white shirt) and Amelia Boynton (nee Platts, later Robinson, 1911 - 2015) (second right, in flower-print dress) lead a march of a pro-integration march, Cambridge, Maryland, May 1964. (Photo by Francis Miller/The LIFE Picture Collection via Getty Images)" />
                </div>
              </div>
            }
            rightPanel={
              <div {...tabbable} className="amelia__right">
                <div className="quote" ref="quote">
                  <Image image={Quote} />
                </div>
                <p className="subhead" ref="phrase">
                  A voteless people is a hopeless people
                </p>
                <h5 className="highlight" ref="ameliaName">
                  Amelia Boynton
                </h5>
                <p className="caption" ref="slogan">
                  Campaign slogan in her
                  <br />
                  1964 bid for Congress
                </p>
              </div>
            }
          />
        </div>
        <div className="protest">
          <SplitView
            leftPanel={
              <div className="protest__left" ref="protestLeft">
                <p {...tabbable} className="alt" ref="topParagraph">
                  Change takes hard work, and it’s often done
                  <br />
                  out of the spotlight.
                </p>
                <h3 {...tabbable} ref="title">
                  In Selma, activists and organizations:
                </h3>
                <div className="subhead">
                  <div {...tabbable} className="topic" ref="topic1">
                    <div className="highlight">1. </div>
                    <div>Kept people informed</div>
                  </div>
                  <div {...tabbable} className="topic" ref="topic2">
                    <div className="highlight">2. </div>
                    <div>Worked out the logistics of protests</div>
                  </div>
                  <div {...tabbable} className="topic" ref="topic3">
                    <div className="highlight">3. </div>
                    <div>
                      Went door-to-door to convince people of the need for
                      change
                    </div>
                  </div>
                </div>
              </div>
            }
            rightPanel={
              <div {...tabbable} className="protest__right" ref="protestRight">
                <p className="alt" ref="goals">
                  Different voting rights groups—SNCC, the DCVL and the
                  SCLC—shared many of the same goals and often the same dangers,
                  but their philosophical approaches and tactical plans were
                  often different and sometimes in conflict.
                </p>
              </div>
            }
          />
        </div>
      </FullScreenContainer>
    );
  }
}

const animation = ({ context, timeline, easeOut, easeIn }) => {
  const {
    introText1,
    introText2,
    panelRef,
    ameliaRef,
    slcBg,
    ameliaImg,
    quote,
    phrase,
    ameliaName,
    ameliaLeft,
    slogan,
    topParagraph,
    title,
    topic1,
    topic2,
    topic3,
    goals,
    firstPanelContent,
    protestLeft,
    protestRight,
    panelOneContainer,
    panelTwoContainer
  } = context.refs;

  hideAllRefs(context.refs, ['panelRef']);
  const credits = '.image-caption';

  TweenMax.set([firstPanelContent, panelOneContainer, panelTwoContainer], { ...showElemWithAria });
  TweenMax.set([introText1, introText2], { ...hideFromBelow });

  timeline.to(introText1, 1.5, { ...revealFromBelow, ...easeOut });
  timeline.to(introText2, 1.5, { ...revealFromBelow, ...easeOut }, '-=1.25');
  timeline.to([panelOneContainer, panelTwoContainer], 0.1, { ...addTab });

  timeline.markers.createTimelineMarker();
  timeline.to([panelOneContainer, panelTwoContainer], 0.1, { ...removeTab });
  timeline.to(firstPanelContent, 0.5, { ...hideElementWithAria });
  timeline.to(
    [ameliaRef, ameliaLeft],
    0.5,
    { ...showElemWithAria },
    '-=0.5'
  );

  timeline.fromTo(
    ameliaImg,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut }
  );
  timeline.to([ameliaImg], 0.1, { ...addTab });
  timeline.fromTo(
    quote,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut },
    '-=1.5'
  );
  timeline.fromTo(
    phrase,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut },
    '-=1.5'
  );

  timeline.fromTo(
    ameliaName,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut },
    '-=1.25'
  );
  timeline.fromTo(
    slogan,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut },
    '-=1.5'
  );

  timeline.markers.createTimelineMarker();

  timeline.to([panelRef], 0.75, { ...hideElementWithAria });

  timeline.to(
    [ameliaImg, quote, '.amelia .right'],
    1,
    { ...hideElementWithAria },
    '-=0.75'
  );
  timeline.to([ameliaImg], 0.1, { ...removeTab });
  timeline.to(phrase, 1, { ...hideElementWithAria }, '-=1');
  timeline.to(ameliaName, 1, { ...hideElementWithAria }, '-=1');
  timeline.to(
    [slogan, ameliaLeft],
    1,
    { ...hideElementWithAria },
    '-=1'
  );
  timeline.to(slcBg, 1, { ...showElemWithAria, ...addTab }, '-=0.25');


  timeline.to(protestLeft, 0.5, { ...showElemWithAria });
  timeline.fromTo(
    topParagraph,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=0.5'
  );
  timeline.fromTo(
    title,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.25'
  );
  timeline.fromTo(
    topic1,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.25'
  );
  timeline.fromTo(
    topic2,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.5'
  );
  timeline.fromTo(
    topic3,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.5'
  );

  timeline.to(protestRight, 0.5, { ...showElemWithAria }, '-=1.25');
  timeline.fromTo(
    goals,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.25'
  );

  timeline.markers.createTimelineMarker();
};

const query = graphql`
  query {
    SclcBg: file(
      relativePath: {
        eq: "chapter-4/scene-1/images/ch-4-image-sclc-background@2x.png"
      }
    ) {
      ...largeImage
    }
    Amelia: file(
      relativePath: {
        eq: "chapter-4/scene-1/images/ch-4-image-amelia-boynton@2x.png"
      }
    ) {
      ...smallImage
    }
    Quote: file(relativePath: { eq: "chapter-2/scene-9/images/quote@2x.png" }) {
      ...doodleImage
    }
    Lorraine: file(
      relativePath: {
        eq: "chapter-4/scene-1/images/ch-4-image-lorraine-toussaint@2x.png"
      }
    ) {
      ...smallImage
    }
    panelOne: file(
      relativePath: { eq: "chapter-4/scene-1/images/panel-1@2x.png" }
    ) {
      ...smallImage
    }
    panelTwo: file(
      relativePath: { eq: "chapter-4/scene-1/images/panel-2@2x.png" }
    ) {
      ...smallImage
    }
    panelThree: file(
      relativePath: { eq: "chapter-4/scene-1/images/panel-3@2x.png" }
    ) {
      ...smallImage
    }
  }
`;

const SceneComponent = scene(timeline(C4S1, { animation }));
export default props => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
