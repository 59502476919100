import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import Image from 'components/Image';
import TabbedContent from 'components/TabbedContent';

import './chapter-4-scene-7.scss';

const getTabsData = images => [
  {
    title: `President: Lyndon Baines Johnson`,
    leftContent: images.Meeting.childImageSharp.fluid.src,
    leftAlt: 'In the White House, US President Lyndon Johnson (1908 - 1973) meets with Civil Rights leaders, from left, National Association for the Advancement of Colored People (NAACP) Executive Secretary Roy Wilkins (1901 - 1981), co-founder of the Committee of Racial Equality (CORE) James L. Farmer Jr (1920 - 1999), President of the Southern Leadership Conference Dr. Martin Luther King Jr (1929 - 1968), and Executive Director of National Urban League Whitney Young (1912 - 1971), Washington DC, January 28, 1964. (Photo by PhotoQuest/Getty Images)',
    rightContent: (
      <div className="tab1__left">
        <h3>U.S. President:<br />Lyndon Baines Johnson</h3>
        <p className="alt">
          Signed the 1964 Civil Rights Act to end segregation.<br/><br/>
          Some civil rights activists distrusted him because of his Senate career when he was a close associate of segregationists like Richard Russell (Democrat-Ga.) and because of his opposition to the Mississippi Freedom Democratic Party in 1964.<br/><br/>
          Worked closely with King in Selma and ultimately signs the Voting Rights Act into law in August 1965.
        </p>
      </div>
    )
  },
  {
    title: `Assistant Attorney General for Civil Rights: John Doar`,
    leftContent: (
      <div className="tab2__left" role='img' aria-label="john doar" style={{backgroundImage: `url(${images.Doar.childImageSharp.fluid.src})`}}>
        <Image image={images.Doodle} alt=""/>
      </div>
    ),
    rightContent: (
      <div className="tab2__right">
        <h3>Assistant Attorney General for Civil Rights: John Doar</h3>
        <p className="alt">
          Led the Department of Justice’s Civil Rights Division.<br/><br/>
          Worked to uphold the constitutional rights of civil rights protesters facing violence and arrest.
        </p>
      </div>
    )
  },
  {
    title: `Federal Bureau of Investigation Director: J. Edgar Hoover`,
    leftContent: images.Hoover.childImageSharp.fluid.src,
    leftAlt: 'F.B.I. director J. Edgar Hoover speaks on the phone in his office in Washington, D.C., April 30, 1954. (AP Photo/Dan Grossi)',
    rightContent: (
      <div className="tab3__right">
        <h3>Federal Bureau of Investigation Director: J. Edgar Hoover</h3>
        <p className="alt">
          Kept files and wiretaps on King and other activists in the movement that Hoover viewed as “subversives.”<br/><br/>
          Chose not to protect civil rights workers or prosecute the KKK for assassinations and murders.
        </p>
      </div>
    )
  }
]

class Scene7TabbedContent extends React.Component {
  constructor(props) {
    super(props)
    this.tabsData = getTabsData(props.data)
  }

  render() {
    return (
      <TabbedContent
        className="chapter-4-scene-7__tabs"
        theme="dark"
        title="Federal Government"
        tabsData={this.tabsData}/>
    );
  }
}

const query = graphql`
  query {
    Meeting: file(
      relativePath: { eq: "chapter-4/scene-7/images/ch-4-image-mlk-lbj-meeting@2x.png" }
    ) {
      ...mediumImage
    }
    Doar: file(
      relativePath: { eq: "chapter-4/scene-7/images/ch-4-image-john-doar@2x.png" }
    ) {
      ...mediumImage
    }
    Hoover: file(
      relativePath: { eq: "chapter-4/scene-7/images/ch-4-image-jedgarhoover@2x.png" }
    ) {
      ...mediumImage
    }
    Doodle: file(
      relativePath: { eq: "chapter-4/scene-7/images/ch-4-doodle-arrow-john-doar@2x.png" }
    ) {
      ...doodleImage
    }
  }
`;

export default (props) => {
  const data = useStaticQuery(query);
  return <Scene7TabbedContent data={data} {...props}/>;
};
